:root {
    --color-primary: #00AB9D;
    --color-primary-dark: #00acc1;
    --color-primary-light: #64b5f6;
    --color-primary-text: #ffffff;
    --color-primary-text-dark: #b2ebf2;
    --color-primary-text-light: #ffffff;
    --color-accent: #ffc107;
    --color-accent-dark: #ffb300;
    --color-accent-light: #ffeb3b;
    --color-accent-text: #000000;
    --color-accent-text-dark: #000000;
    --color-accent-text-light: #000000;
    --color-background: #ffffff;
    --color-background-dark: #e0e0e0;
    --color-background-light: #ffffff;
    --color-background-text: #000000;
    --color-background-text-dark: #000000;
    --color-background-text-light: #000000;
    --color-background-secondary: #fafafa;
    --color-background-secondary-dark: #f5f5f5;
    --color-background-secondary-light: #ffffff;
    --color-background-secondary-text: #000000;
    --color-background-secondary-text-dark: #000000;
    --color-background-secondary-text-light: #000000;
    --color-background-tertiary: #fafafa;
    --color-background-tertiary-dark: #f5f5f5;
    --color-background-tertiary-light: #ffffff;
    --color-background-tertiary-text: #000000;
    --color-background-tertiary-text-dark: #000000;
    --color-background-tertiary-text-light: #000000;
    --color-background-quaternary: #fafafa;
    --color-background-quaternary-dark: #f5f5f5;
    --color-background-quaternary-light: #ffffff;   
}